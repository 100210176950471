<!-- ----------------------------------------------------------------------- -->
<!--
name    : JOB SITES VIEW

type    : view

route   : /serviceRequests
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="job-sites-view">
    <!-- router-view will be replaced with whichever route is active -->
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'JobSitesView',
    components: {},
  };
</script>
